import {
  faAmazon,
  faApple,
  faFacebookF,
  faInstagram,
  faSoundcloud,
  faSpotify,
  faTiktok,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

interface SocialIconsTypes {
  icon?: any;
  link: string;
  img?: string;
  isPandora?: boolean;
}

export const data: SocialIconsTypes[] = [
  {
    icon: faSpotify,
    link: "https://open.spotify.com/artist/4mAsHgZzoZvU0eMbHOB60X?si=RZnngqWrRjCRp746gIgfTw",
  },
  {
    icon: faApple,
    link: "https://music.apple.com/us/artist/yhapojj/1495532191",
  },
  // {
  //   icon: faAmazon,
  //   link: "#",
  // },
  {
    icon: faTwitter,
    link: "https://twitter.com/yhapojj1?s=21&t=uUgDnz16BnzhsCYIL2K0LQ",
  },
  {
    icon: faInstagram,
    link: "https://www.instagram.com/yhapojj/",
  },
  // {
  //   icon: faFacebookF,
  //   link: "#",
  // },
  {
    icon: faYoutube,
    link: "https://www.youtube.com/@OfficialYhapojj",
  },
  {
    icon: faTiktok,
    link: "https://www.tiktok.com/@stikklyfeyhapo?_t=8ZAK6m8Dl7b&_r=1",
  },
  {
    icon: faSoundcloud,
    link: "https://soundcloud.com/115666777",
  },
  {
    icon: "audiomack",
    link: "https://audiomack.com/yhapojj",
  },
];
