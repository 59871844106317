import { RoutePattern } from "../../routes/RoutePattern";
import Showcase from "./Showcase";

const Home = () => {
  return (
    <div
      id={RoutePattern.HOME}
      className="relative flex flex-col h-full md:min-h-screen"
    >
      <Showcase />
    </div>
  );
};

export default Home;
