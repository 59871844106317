const text = `
When you think of Huntsville, its rich history of creating NASA rockets is perhaps the
first thing that springs to mind, and it’s fair to say few would associate this Alabama
city (nicknamed the “Rocket City”) with any kind of thriving Hip-Hop scene. It’s this 
reality that makes fast-rising local rapper <b>Yhapojj</b> (real name Jamaruean Micaiah 
Jones) such a fascinating prospect, with the versatile artist inspired to show the 
world that his hometown is about much more than just white scientists plotting a 
route into outer space. With uniquely slurred, pained vocals that recall 
vintage <b>Young Thug</b>, and a vulnerable core to his songwriting, where concepts like 
heartbreak and isolation are approached without fear, <b>Yhapojj</b> is essentially 
creating a Huntsville rap scene from scratch. On the potent <b>“Hold It Down”</b>, the 
artist pledged that “you’ve got to stand for something or you gon’ fall for nothing”, 
and he says his mission statement is to help young black males deal with their 
demons rather than bury them under layers of pride.
<br/>
<br/>
“I really don’t look at a lyric like that as being weak,” the 19-year-old explains, 
referring to another bar [“Heard you’re doing fine, but I’m really not okay”] from the
lovelorn <b>“SSorry I Can’t SStop”</b>. “I just want to show my n*ggas that sharing their
pain takes real strength. It's dangerous in Huntsville, just the other week there was 
a mass shooting! It isn’t sweet here, so letting your demons out is important so that
bad energy don't get pent up inside. I could be a rapper who just makes turnt music
all the time, but that ain't how we be feeling all the time!”
<br/>
<br/>
<b>Yhapojj</b> was inspired to take music seriously after going to prison on a gun charge, 
a stint that resulted in him writing dozens of confessional songs while locked up. He 
initially gained recognition after collaborating with Ohio-based underground rap 
innovator <b>Izaya Tiji</b> – while being fiercely independent, solo songs like <b>“Hurt My 
Feelings” (1.3M Streams)</b> and <b>“Hittin”</b> have surpassed the six figure mark on 
SoundCloud, with his overall US streaming numbers now in excess of one million. 
The latter song is the heart of his latest project called <b>Gossip</b> Girl, an album that 
sees Yhap experimenting with a high falsetto and trying to channel the strength of 
his mother, Jamie, whose energy can also be felt on previous albums like <b>Yiix 
Yhap</b> and <b>When Angels Cry</b>.
<br/>
<br/>
Drawing inspiration from the likes of <b>Freddie Mercury</b>, <b>Michael 
Jackson</b> and <b>ILoveMakonnen</b>, <b>Yhapojj</b> has began to cultivate his very own sound 
and wave, emphasizing relatability within his music, especially when you want to 
motivate the poor. “I’ve been studying Queen’s old performances and I love that 
Freddie has the audience in the palm of his hand just by standing tall,”  he says. A 
reference to <b>Queen</b> might not be something you’d expect from a rapper from 
Alabama, but subverting expectations is what <b>Yhapojj</b> is all about. It feels like a 
real fire is now burning behind him, just like the rockets his city used to launch into 
space. “The rap game be so saturated and dried out, I just try to bring something 
unique and refreshing,”  he concludes.
<br/>
<br/>
<b>Yhapojj</b> tends to make songs that reflect the ups and downs of his days, with songs
like the aforementioned <b>“Sstay Away From Me”</b> written to cope with the anger of 
falling out with a backstabbing friend. Whether he’s flexing about putting his feet up
at the house of an enemy’s girlfriend [<b>“RATCHET”</b>] or standing in the rain just to 
feel something after a brutal break-up [see <b>“Forever”</b>], listening to <b>Yhapojj</b>’s song
has the intimacy of a late night chat with a friend, desperate to get something off 
their chest. Therefore, it’s no surprise that Yhap can regularly be found 
on Instagram live-streaming his studio sessions, letting fans play a direct role in his 
creative process.
<br/>
<br/>
Currently riding off his biggest song to date, the recently-released <b>“You Lookin 
Gud”</b>, <b>YhapoJJ</b> has racked up over <b>10 Million Streams</b> in 2023 alone. There have 
been over 4,000 creations to the song’s sound on TikTok and his streaming numbers
are up +400% compared to his past two singles. Serving as the backbone to his 
recently released EP <b>Evolution of Xur</b> which dropped pm 11/15, <b>YhapoJJ</b> is ready 
to take advantage of his moment by fully leaning into the wolf aesthetic and playing
into the mystical sonics inspired by <b>Twilight</b>. In the process, <b>Yhapojj</b> is continuing 
to elevate his sound while staying true to his unique influences and intimate songwriting capabilities.
`;

export default function About() {
  return (
    <div
      id="about"
      className="bg-black px-[5vw] pb-[10vh] pt-[15vh] font-secondary"
    >
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
}
