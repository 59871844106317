import { RoutePattern } from "../../routes/RoutePattern";

interface RouteTypes {
  name: string;
  to?: string;
  smooth: boolean;
  isAnchor?: boolean;
  isEmail?: boolean;
}

export const data: RouteTypes[] = [
  {
    name: RoutePattern.ABOUT,
    to: "/" + RoutePattern.ABOUT,
    smooth: false,
  },
  {
    name: RoutePattern.CONTACT,
    smooth: false,
    isEmail: true,
    to: "david@simplestupidrecords.com",
  },
  {
    name: RoutePattern.MUSIC,
    smooth: false,
    isAnchor: true,
    to: "https://linktr.ee/Yhapojj",
  },
  // {
  //   name: RoutePattern.STREAMING,
  //   smooth: true,
  // },
  // {
  //   name: RoutePattern.BIO,
  //   smooth: true,
  // },
  // {
  //   name: RoutePattern.TOUR,
  //   smooth: true,
  // },
  // {
  //   name: RoutePattern.MERCH,
  //   smooth: true,
  // },
  // {
  //   name: RoutePattern.GALLERY,
  //   smooth: true,
  // },
  // {
  //   name: RoutePattern.SUBSCRIBE,
  //   smooth: true,
  // },
];
